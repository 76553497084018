import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";

import { AudioPlayer } from "../../../components/Media";
import Contents from "../../../components/Contents";
import { ContentBlock, TextBlock } from "../../../components/Blocks";
import Pages from "../../../components/Pages";
import { makeUrl, removePreloader } from "../../../helpers";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";
import { Share } from "../../../widgets";
import EmptyData from "../../../components/EmptyData";
import {
	MetaDateModified,
	MetaDescription,
	MetaDateDateCreated,
	MetaDatePublished,
	MetaTranscript,
	MetaImage,
} from "../../../components/Meta";

export const query = graphql`
	query mediaRadioRecordsDataQuery($slug: String!) {
		hasura {
			v_media_radio_records(where: { slug: { _eq: $slug } }) {
				title_full
				audio
				page_title_full
				page_title_short
				slug
				main_image
				main_image_preview
				seo
				content_blocks
				created_at
				updated_at
			}
		}
	}
`;

const FETCH_QUERY = gql`
	query FetchData($location: String!) {
		v_media_radio_records(where: { slug: { _eq: $location } }) {
			title_full
			audio
			page_title_full
			page_title_short
			slug
			main_image
			main_image_preview
			seo
			content_blocks
			created_at
			updated_at
		}
	}
`;

export default function MediaRadioRecordPage({ location, data }) {
	const hasuraData = data.hasura;
	const path = last(compact(location.pathname.split("/")));

	const [isMount, setIsMount] = useState(false);
	const [audios, setAudios] = useState(get(hasuraData, "v_media_radio_records[0]", {}));

	const { loading, data: fetchData, error } = useQuery(FETCH_QUERY, {
		variables: {
			location: path,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	});

	useEffect(() => {
		setIsMount(true);
	}, []);

	useEffect(() => {
		if (isMount && fetchData && !error) {
			setAudios(get(fetchData, "v_media_radio_records[0]", {}));
		}

		removePreloader(!loading || error);
	}, [loading]);

	const { slug, main_image_preview, main_image, page_title_short, page_title_full, created_at, updated_at } = audios;

	const url = makeUrl.mediaAudio({ slug });
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));

	const audioSrc = get(audios, "audio.src", "");
	const title_full = get(audios, "title_full", "");
	const content_blocks = get(audios, "content_blocks", []);

	return audios ? (
		<Pages entity={audios} url={url} >
			<div itemScope itemType="https://schema.org/AudioObject">
				<MetaDateModified content={updated_at} />
				<MetaDescription content={title_full} />
				<MetaDateDateCreated content={created_at} />
				<MetaDatePublished content={created_at} />
				<MetaImage content={imageSrc} />
				<MetaTranscript content={"AudioObject"} />
				<ContentBlock key={"breadcrumbs"}>
					<div className="container">
						<Breadcrumbs
							currentLabel={title_full}
							pageContext={
								{
									item: {
										title_full: "Медиатека",
										slug: "media",
									},
								}
							}
						/>
					</div>
				</ContentBlock>
				<ContentBlock key={"fileList"}>
					<div className={"container"}>
						<TextBlock title={title_full}>
							<AudioPlayer url={audioSrc} format={"HH:mm:ss"} />
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"contents"}>
					<div className={"container"}>
						<Contents items={content_blocks} />
					</div>
				</ContentBlock>
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

MediaRadioRecordPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

MediaRadioRecordPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
